/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-20 19:31:12
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-07 12:15:15
 * @FilePath: /mediatom-web/src/mixins/rateAndMoneyMethod.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { mapState } from 'vuex'
export default {
  data () {
    return {
      rotaPropList: [
        'requestFilledRate',
        'advRequestFilledRate',
        'impressRate',
        'clickRate',
        'askPriceFilledRate',
        'bidSuccessRate',
        'impressRequestRate',
        'clickGap',
        'impressionGap',
        'profitRate',
        'unitFilledRate',
        'unitCtr',
        'display',
        'revenuePercentage',
        'awakenSuccessRate',
        'timeoutRate',
        'permeability',
        'advRequestFilledRate',
        'cacheImpressRate',
        'huanqiRate',
        'shouhuanRate',
        'huanduanConfirmRate'
      ],
      moneyPropList: [
        'advRequestPrice',
        'yesterdayRevenue',
        'sevenDaysRevenue',
        'monthRevenue',
        'sumRevenue',
        'income',
        'cpc',
        'advPrice',
        'estimatedRevenueEcpm',
        'advCpc',
        'advEcpm',
        'unitRevenue',
        'unitEcpm',
        'ecpm',
        'responseCost',
        'advRequestCost',
        'totalCost',
        'shareIncome',
        'profit',
        'shareProfit',
        'cpaProfit',
        'transformPrice',
        'askPriceSum',
        'advPriceQuanwangShouhuan'
      ]
    }
  },
  computed: {
    ...mapState({
      moneyText: (state) => +state.user.cur === 1 ? '$' : '￥'
    })
  },
  methods: {
    // 是否是百分比
    isRota (prop) {
      return this.rotaPropList.includes(prop)
    },
    // 是否是金额
    isMoney (prop) {
      return this.moneyPropList.includes(prop)
    }
  }
}
